<template>
  <div>
    <div class="grid w-full items-center gap-1.5">
      <label :for="name" class="">{{ label }}</label>
      <div class="flex items-center gap-3">
        <label :for="name" class="input">
          <Field
            type="file"
            :name="name"
            :id="name"
            @change="
              (event) => {
                handleImageChange(event);
              }
            "
            class="hidden"
            multiple
            accept="image/*"
          />
          <span class="flex items-center justify-center gap-2 label">
            <i class="bx bx-upload"></i>
            <span class="block truncate"> اختر الملفات </span>
          </span>
        </label>
      </div>
      <ErrorMessage :name="name" class="error-message" />

      <!--  -->
      <div class="grid grid-cols-4 gap-8 my-12">
        <div
          v-for="(element, index) in imageFile"
          :key="index"
          class="relative w-full h-full group"
        >
          <Image
            :src="element.photoPath"
            class="absolute object-cover w-full rounded-2xl h-52"
          />
          <div
            class="flex items-center w-full text-white transition-all duration-300 opacity-0 rounded-2xl h-52 bg-danger/25 translate-y-28 group-hover:translate-y-0 group-active:opacity-100 group-hover:opacity-100"
          >
            <button
              @click="deleteImage(element.imageId!)"
              class="flex items-center justify-center w-1/2 p-3 mx-auto text-sm bg-white rounded-xl h-1/2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.875"
                height="19.876"
                viewBox="0 0 19.875 19.876"
              >
                <path
                  id="delete-svgrepo-com"
                  d="M66.129,68.2H64.71a.71.71,0,0,1,0-1.42h5.679V64.646a.71.71,0,0,1,.71-.71h5.679a.71.71,0,0,1,.71.71v2.131h5.679a.71.71,0,0,1,0,1.42h-1.42V83.1a.71.71,0,0,1-.71.71h-14.2a.71.71,0,0,1-.71-.71Zm9.938-1.42v-1.42H71.808v1.42ZM67.549,82.393H80.326V68.2H67.549Zm4.259-2.839a.71.71,0,0,1-.71-.71v-7.1a.71.71,0,1,1,1.42,0v7.1A.71.71,0,0,1,71.808,79.553Zm4.259,0a.71.71,0,0,1-.71-.71v-7.1a.71.71,0,0,1,1.42,0v7.1A.71.71,0,0,1,76.067,79.553Z"
                  transform="translate(-64 -63.936)"
                  fill="#da2834"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>
<script setup lang="ts">
import { Field, ErrorMessage } from "vee-validate";
import { nextTick, ref, toRef, watch } from "vue";

interface Image {
  file: File;
  photoPath: string;
  imageId?: string;
}

const props = defineProps<{
  name: string;
  label: string;
  images?: Image[];
}>();

const imageFile = ref<Image[]>(props.images || []);

const emit = defineEmits<{
  change: [value: Image[]];
  delete: [id: string];
}>();

const handleImageChange = (e: any) => {
  e.preventDefault();
  const files = e.target.files;

  Object.values(files).forEach((element: any) => {
    imageFile.value.push({
      imageId: Math.random().toString(),
      file: element,
      photoPath: URL.createObjectURL(element),
    });
    nextTick(() => {
      emit("change", imageFile.value);
    });
  });
};

const deleteImage = (id: string) => {
  if (!id) return;
  imageFile.value = imageFile.value.filter((el) => el.imageId != id);
  if (isNaN(+id)) {
    emit("delete", id);
  }
  emit("change", imageFile.value);
};
</script>
