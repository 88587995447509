<template>
  <div
    dir="rtl"
    class="relative grid w-full h-screen grid-cols-12 text-gray-800 bg-accent"
  >
    <div class="relative col-span-full lg:col-span-2">
      <SideBar :is_active="is_active" @toggleSidebar="toggleSidebar" />
    </div>
    <main
      class="relative flex flex-col overflow-y-scroll transition-all ease-in duraltion-150 col-span-full lg:col-span-10 hiddenScrollbar"
    >
      <Navbar @toggleSidebar="toggleSidebar" />
      <Breadcrumb />

      <!-- content area -->
      <section
        class="container relative h-full p-8 mx-auto overflow-x-hidden overflow-y-scroll hiddenScrollbar"
      >
        <RouterView />
      </section>
      <footer className="p-5 text-center bg-white  bg-clip-padding  backdrop-filter backdrop-blur-2xl bg-opacity-70">
        <p>
          <span class="text-primary">Quadro</span> ©
          {{ new Date().getFullYear() }}
        </p>
      </footer>
    </main>
  </div>
</template>

<script lang="ts" setup>
import SideBar from "./SideBar.vue";
import { onMounted, ref } from "vue";
import Breadcrumb from "./Breadcrumb.vue";
import Navbar from "./NavBar.vue";
import { useAuth } from "../controllers/auth";

const auth = useAuth();

const is_active = ref(true);

const toggleSidebar = () => {
  is_active.value = !is_active.value;
};

const handleResize = () => {
  if (window.innerWidth > 1024) {
    is_active.value = true;
  } else {
    is_active.value = false;
  }
};

window.addEventListener("resize", handleResize);

onMounted(() => {
  auth.getRoles();
});
</script>

<style></style>
