import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import { RoleType } from "../../core/constant/EUserRoles";

const routes: RouteRecordRaw = {
  path: "/wallet/",
  component: RouterView,
  name: ERoutesName.WALLET,
  redirect: { name: ERoutesName.WALLET_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, RoleType.Admin],
    breadcrumb: [
      {
        text: "ادارة المحافظ",
        router_name: ERoutesName.WALLET,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.WALLET_INDEX,
      component: () => import("@/pages/wallet/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة  المحافظ",
            router_name: ERoutesName.WALLET,
          },
          {
            text: "قائمة المحافظ",
            router_name: ERoutesName.WALLET_INDEX,
          },
        ],
      },
    },
    {
      path: "show/:id",
      name: ERoutesName.WALLET_SHOW,
      component: () => import("@/pages/wallet/screens/show.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة  المحافظ",
            router_name: ERoutesName.WALLET,
          },
          {
            text: "قائمة المحافظ",
            router_name: ERoutesName.WALLET_INDEX,
          },
          {
            text: "عرض  محفظة",
            router_name: ERoutesName.WALLET_SHOW,
          },
        ],
      },
    },

    {
      path: "charge/:id",
      name: ERoutesName.WALLET_CREATE,
      component: () => import("@/pages/wallet/screens/create.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة المحافظ ",
            router_name: ERoutesName.WALLET,
          },
          {
            text: "قائمة المحافظ ",
            router_name: ERoutesName.WALLET_INDEX,
          },
          {
            text: "اضافة  محفظة",
            router_name: ERoutesName.WALLET_CREATE,
          },
        ],
      },
    },
  ],
};

export default routes;
