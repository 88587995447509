export enum ERoutesName {
  MAIN = "MAIN",
  DASHBOARD = "Dashboard",
  AUTH = "auth",
  LOGIN = "login",
  RESET_PASSWORD = "resetPassword",
  FORGOT_PASSWORD = "forgot-password",
  USERS_MANAGEMENT = "UsersManagement",
  USERS_INDEX = "UsersIndex",
  USERS_CREATE = "UsersCreate",
  USERS_EDIT = "UsersEdit",
  USERS_SHOW = "UsersShow",
  USERS_DELETE = "UsersDelete",
  FORBIDDEN = "Forbidden",
  NOT_FOUND = "NotFound",
  SERVER_ERROR = "ServerError",
  UNAUTHORIZED = "Unauthorized",
  PROFILE = "Profile",
  SETTINGS = "Settings",
  CITIES = "Cities",
  CITIES_INDEX = "CitiesIndex",
  CITIES_CREATE = "CitiesCreate",
  CITIES_EDIT = "CitiesEdit",
  CUSTOMER_SUPPORT = "CustomerSupport",
  CUSTOMER_SUPPORT_INDEX = "CustomerSupportIndex",
  CUSTOMER_SUPPORT_CREATE = "CustomerSupportCreate",
  CUSTOMER_SUPPORT_EDIT = "CustomerSupportEdit",
  CUSTOMER_SUPPORT_SHOW = "CustomerSupportShow",
  CATEGORIES = "Categories",
  CATEGORIES_INDEX = "CategoriesIndex",
  CATEGORIES_CREATE = "CategoriesCreate",
  CATEGORIES_EDIT = "CategoriesEdit",
  CATEGORIES_SHOW = "CategoriesShow",
  SUB_CATEGORIES = "SubCategories",
  SUB_CATEGORIES_INDEX = "SubCategoriesIndex",
  SUB_CATEGORIES_CREATE = "SubCategoriesCreate",
  SUB_CATEGORIES_EDIT = "SubCategoriesEdit",
  SUB_CATEGORIES_SHOW = "SubCategoriesShow",
  BRANDS = "Brands",
  BRANDS_INDEX = "BrandsIndex",
  BRANDS_CREATE = "BrandsCreate",
  BRANDS_EDIT = "BrandsEdit",
  BRANDS_SHOW = "BrandsShow",
  TERMS = "TERM",
  TERMS_INDEX = "TermsIndex",
  TERMS_CREATE = "TermsCreate",
  TERMS_EDIT = "TermsEdit",
  TERMS_SHOW = "TermsShow",
  CAR_MAKER = "CarMaker",
  CAR_MAKER_INDEX = "CarMakerIndex",
  CAR_MAKER_CREATE = "CarMakerCreate",
  CAR_MAKER_EDIT = "CarMakerEdit",
  CAR_MAKER_SHOW = "CarMakerShow",
  CAR_MODEL = "CarModel",
  CAR_MODEL_INDEX = "CarModelIndex",
  CAR_MODEL_CREATE = "CarModelCreate",
  CAR_MODEL_EDIT = "CarModelEdit",
  CAR_MODEL_SHOW = "CarModelShow",
  CAR_SUB_MODEL = "CarSubModel",
  CAR_SUB_MODEL_INDEX = "CarSubModelIndex",
  CAR_SUB_MODEL_CREATE = "CarSubModelCreate",
  CAR_SUB_MODEL_EDIT = "CarSubModelEdit",
  CAR_SUB_MODEL_SHOW = "CarSubModelShow",
  PRODUCT_TAX = "ProductTax",
  PRODUCT_TAX_INDEX = "ProductTaxIndex",
  PRODUCT_TAX_CREATE = "ProductTaxCreate",
  PRODUCT_TAX_EDIT = "ProductTaxEdit",
  PRODUCT_TAX_SHOW = "ProductTaxShow",
  PRODUCT_UNIT = "ProductUnit",
  PRODUCT_UNIT_INDEX = "ProductUnitIndex",
  PRODUCT_UNIT_CREATE = "ProductUnitCreate",
  PRODUCT_UNIT_EDIT = "ProductUnitEdit",
  VENDORS = "Vendors",
  VENDORS_INDEX = "VendorsIndex",
  VENDORS_CREATE = "VendorsCreate",
  VENDORS_EDIT = "VendorsEdit",
  VENDORS_SHOW = "VendorsShow",
  PRODUCTS = "Products",
  PRODUCTS_INDEX = "ProductsIndex",
  PRODUCTS_CREATE = "ProductsCreate",
  PRODUCTS_IMPORT = "ProductsImport",
  PRODUCTS_EDIT = "ProductsEdit",
  PRODUCTS_SHOW = "ProductsShow",
  PRODUCTS_VENDOR_PRODUCTS = "ProductsVendorProducts",
  CUSTOMER = "Customer",
  CUSTOMER_INDEX = "CustomerIndex",
  CUSTOMER_SHOW = "CustomerShow",
  APPLICATION_OPTION = "ApplicationOption",
  APPLICATION_OPTION_INDEX = "ApplicationOptionIndex",
  APPLICATION_OPTION_CREATE = "ApplicationOptionCreate",
  BANNER = "Banner",
  BANNER_INDEX = "BannerIndex",
  BANNER_CREATE = "BannerCreate",
  DELIVRY_OPTIONS = "DeliveryOptions",
  DELIVRY_OPTIONS_INDEX = "DeliveryOptionsIndex",
  DELIVRY_OPTIONS_CREATE = "DeliveryOptionsCreate",
  DELIVRY_OPTIONS_EDIT = "DeliveryOptionsEdit",
  ORDERS = "Orders",
  ORDERS_INDEX = "OrdersIndex",
  ORDERS_SHOW = "OrdersShow",
  FILTER_TOOL = "FilterTool",
  FILTER_TOOL_INDEX = "FilterToolIndex",
  FILTER_TOOL_CREATE = "FilterToolCreate",
  FILTER_TOOL_EDIT = "FilterToolEdit",
  COUPONS = "Coupons",
  COUPONS_INDEX = "CouponsIndex",
  COUPONS_CREATE = "CouponsCreate",
  COUPONS_EDIT = "CouponsEdit",
  WALLET = "Wallet",
  WALLET_INDEX = "WalletIndex",
  WALLET_SHOW = "WalletShow",
  WALLET_CREATE = "WalletCreate",
  BONUSES = "Bonuses",
  BONUSES_INDEX = "BonusesIndex",
  BONUSES_CREATE = "BonusesCreate",
  PAYMENT = "Payment",
  PAYMENT_INDEX = "PaymentIndex",
  PAYMENT_SHOW = "PaymentShow",
  SPLASH_SCREEN = "SplashScreen",
  SPLASH_SCREEN_INDEX = "SplashScreenIndex",
  SPLASH_SCREEN_CREATE = "SplashScreenCreate",
  SPLASH_SCREEN_EDIT = "SplashScreenEdit",
  SAMPLE_FILE = "SampleFile",
  SAMPLE_FILE_INDEX = "SampleFileIndex",
  SAMPLE_FILE_CREATE = "SampleFileCreate",
  SAMPLE_FILE_EDIT = "SampleFileEdit",
}
